const translations = {
  clientVisibleTask: {
    compare: "Compare",
    details: "Details",
    download: "Download",
    haveFeedback: "Have feedback?",
    haveFeedbackDescription:
      "Need additional resources or have a question about the ones provided? Message your Care Partner with feedback and we will get back to you.",
    notAMatch: "Not a match",
    selected: "Selected",
    sendMessage: "Send a message"
  },
  pins: {
    EmployerBenefit: "Benefit",
    FileResource: "File",
    Resource: "Resource",
    Worksheet: "Worksheet"
  },
  sections: {
    drive: {
      browse: "Browse",
      documents: "Documents",
      dragAndDrop: "Drag and drop files, or",
      folders: "Folders",
      modifiedAt: "Modified {date}",
      uploadFiles: "Upload files",
      uploadedAt: "Uploaded {date}",
      worksheets: "Worksheets"
    },
    profile: {
      sessionHistory: "Session history",
      signConsentForm: "Signed consent forms",
      titleLovedOne: "Loved one's information",
      titleNeeds: "What are your needs?"
    },
    trackers: {
      description: "Keep track of contacts, medications, and legal materials",
      legal: {
        add: "Add legal file",
        addContinue: "Continue tracking legal files",
        description: "Upload legal files all in one place.",
        download: "Download your legal tracker",
        edit: "Edit legal file",
        title: "Legal tracker",
        track: "Track legal files"
      },
      medical: {
        add: "Add medication",
        addContinue: "Continue tracking medications",
        description: "Organize important medication information.",
        download: "Download your medication list",
        edit: "Edit medication",
        title: "Medications",
        track: "Track medications"
      },
      provider: {
        add: "Add provider",
        addContinue: "Continue tracking providers",
        description: "Add and keep track of providers and their contact information.",
        download: "Download your provider list",
        edit: "Edit provider",
        title: "Providers",
        track: "Track providers"
      },
      title: "Trackers"
    }
  }
};

export default translations;
