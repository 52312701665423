const translations = {
  allowsSameDayConsults: {
    label: "Check to allow same day bookings"
  },
  appointmentBuffer: {
    header: "Calendar buffer",
    subheader: "Give yourself extra time between your calendar events"
  },
  consultNoticeBuffer: {
    label: "Notice required for same day bookings"
  },
  hoursOption: "{count, plural, one {# hour} other {# hours}}",
  maxConsultsPerDay: {
    header: "Limit the number of appointments per day",
    label: "Max appointments per day",
    subheader: "Set a maximum number of Grayce sessions you can have in a day."
  },
  minutesOption: "{count, plural, one {# minute} other {# minutes}}",
  postConsultBuffer: {
    label: "After a session"
  },
  preConsultBuffer: {
    label: "Before a session"
  },
  sameDayBookings: {
    header: "Allow same day bookings?",
    subheader:
      "Allow members to book time with you on the same day, and set an amount of notice required for same day appointments."
  }
};

export default translations;
