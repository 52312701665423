const translations = {
  closed: {
    byMe: "You have left this chat.",
    byOthers: "The other participants have left this chat."
  },
  pending:
    "Note: You can send up to 3 messages while waiting for the other person to join the chat. This helps ensure conversations are welcomed and respectful.",
  waiting: "Responding will accept the chat and open the conversation. Reply when you're ready!"
};

export default translations;
