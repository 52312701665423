/* eslint-disable @typescript-eslint/naming-convention */

const translations = {
  faq: "FAQ",
  info: "Your information",
  logout: "Log out",
  package: "Package summary",
  privacy: "Privacy and account settings",
  privacy_account_deletion: "Delete my account",
  privacy_change_password: "Change password",
  privacy_community_notifications: "Community notifications",
  privacy_email_marketing: "Email marketing",
  privacy_email_notifications: "Messaging preferences",
  privacy_marketing: "Marketing and text reminders",
  privacy_two_factor: "Two factor authentication",
  purchase_hours: "Purchase package",
  refer_a_coworker: "Refer a coworker",
  report_a_problem: "Report a problem",
  title: "Account"
};

export default translations;
