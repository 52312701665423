const translations = {
  heading: {
    inbox: "Messages",
    new: "New message"
  },
  inbox: {
    title: "Inbox"
  },
  search: {
    placeholder: "Search messages"
  },
  toggle: {
    care: "Care team chats{ count, plural, =0 {} other { ({ count })} }",
    community: "Community chats{ count, plural, =0 {} other { ({ count })} }"
  }
};

export default translations;
