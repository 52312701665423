const translations = {
  community: {
    body: "Gather is an online community by Grayce where you can attend events, learn from others, and find peer support.",
    cta: "Join the community",
    heading: "You belong here"
  },
  explore: {
    body: "Explore enriching articles, media, and events for support and guidance through all stages of care.",
    cta: "Explore media",
    heading: "Discover support"
  },
  marketplace: {
    body: "Take advantage of all your employer-sponsored marketplace benefits.",
    cta: "Browse marketplace",
    heading: "Navigate benefits"
  },
  suggestedForYou: {
    heading: "Suggested for you"
  },
  trendingPosts: {
    heading: "Trending posts from the community"
  }
};

export default translations;
