const translations = {
  capture: {
    inputLabel: "File name",
    title: "Capture"
  },
  complete: {
    title: "Upload complete!"
  },
  start: {
    description:
      "Sharing your insurance information helps us provide the best possible care options for you and your loved ones.",
    title: "Upload your insurance"
  }
};

export default translations;
