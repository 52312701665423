const translations = {
  days: {
    /* eslint-disable @typescript-eslint/naming-convention */
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday"
    /* eslint-enable @typescript-eslint/naming-convention */
  },
  header: "Your working hours",
  noWorkingHours: "Off",
  subheader: "Members may schedule appointments with you only during your working hours."
};

export default translations;
