const translations = {
  backToHomeFeed: "Back to home feed",
  dummyDescription: "Description of the group goes here, more information.",
  followGroup: "Join group",
  following: "Joined",
  leaveGroup: "Leave group",
  noMembers: {
    withSearch: "No members found.",
    withoutSearch: "There are no members in this group yet."
  },
  receiveNotifications: "Receive notifications"
};

export default translations;
