const translations = {
  bio: {
    title: "Hi, I'm {name}"
  },
  body: "My goal is to keep you from feeling overwhelmed during stressful situations. We'll figure this out.",
  cta: "Schedule",
  cta2: "Send message",
  helper: "Care Partner bio",
  title: "Let's talk",
  waysWeCanHelp: "Ways we can help:"
};

export default translations;
