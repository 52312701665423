import AccountMenu from "@components/v1/account/AccountMenu.translations";
import ActivateButton from "@components/v1/ActivateButton.translations";
import ActivatePage from "@components/v1/ActivatePage.translations";
import HeroCardCarePlanBody from "@components/v1/cards/HeroCardCarePlanBody.translations";
import HeroCardCarePlanItemsBody from "@components/v1/cards/HeroCardCarePlanItemsBody.translations";
import HeroCardScheduledConsultBody from "@components/v1/cards/HeroCardScheduledConsultBody.translations";
import HeroCardWorkingOnIt from "@components/v1/cards/HeroCardWorkingOnIt.translations";
import CarePlan from "@components/v1/carePlan/CarePlan.translations";
import Goal from "@components/v1/carePlan/Goal.translations";
import Goals from "@components/v1/carePlan/Goals.translations";
import CarePlanActionItemActionGroup from "@components/v1/carePlan/member/CarePlanActionItemActionGroup.translations";
import ClientCommunityPage from "@components/v1/client/ClientCommunityPage.translations";
import ClientVisibleTaskResourceActions from "@components/v1/ClientVisibleTaskResouceActions.translations";
import ClientVisibleTaskResourceUsefulnessButtons from "@components/v1/ClientVisibleTaskResourceUsefulnessButtons.translations";
import ExpertCalendarPage from "@components/v1/expert/calendar/ExpertCalendarPage.translations";
import ExpertConsultBuffersForm from "@components/v1/expert/calendar/ExpertConsultBuffersForm.translations";
import ExpertWorkingHoursList from "@components/v1/expert/calendar/ExpertWorkingHoursList.translations";
import WorkingHourBlockForm from "@components/v1/expert/calendar/WorkingHourBlockForm.translations";
import CommunityNotificationsForm from "@components/v1/forms/CommunityNotificationsForm.translations";
import SearchForm from "@components/v1/forms/SearchForm.translations";
import EmptySelfServeCarePlan from "@components/v1/home/EmptySelfServeCarePlan.translations";
import Home from "@components/v1/home/Home.translations";
import SuggestedForYou from "@components/v1/home/SuggestedForYou.translations";
import JourneySelector from "@components/v1/JourneySelector.translations";
import ActivityHelperText from "@components/v1/messaging/ActivityHelperText.translations";
import ChatThread from "@components/v1/messaging/ChatThread.translations";
import ChatWarning from "@components/v1/messaging/ChatWarning.translations";
import ClientInbox from "@components/v1/messaging/ClientInbox.translations";
import ClosedChats from "@components/v1/messaging/ClosedChats.translations";
import LeaveChatModal from "@components/v1/messaging/LeaveChatModal.translations";
import NotificationCenter from "@components/v1/NotificationCenter.translations";
import OutOfOfficeCalendar from "@components/v1/OutOfOfficeCalendar.translations";
import Providers from "@components/v1/trackers/Providers.translations";
import SignConsentFormWizard from "@components/v1/wizards/SignConsentFormWizard.translations";
import UploadDocumentsWizard from "@components/v1/wizards/UploadDocumentsWizard.translations";
import UploadInsuranceWizard from "@components/v1/wizards/UploadInsuranceWizard.translations";
import ClientCommunityMemberPage from "@components/v2/community/ClientCommunityMemberPage.translations";
import ClientCommunityNotificationPage from "@components/v2/community/ClientCommunityNotificationPage.translations";
import ClientCommunityPostPage from "@components/v2/community/ClientCommunityPostPage.translations";
import Community from "@components/v2/community/Community.translations";
import CommunityGroup from "@components/v2/community/CommunityGroup.translations";
import CommunityMembersSearch from "@components/v2/community/CommunityMembersSearch.translations";
import CommunityNotificationPage from "@components/v2/community/CommunityNotificationPage.translations";
import ConnectionsList from "@components/v2/community/ConnectionsList.translations";
import GroupForm from "@components/v2/community/GroupForm.translations";
import NewChatForm from "@components/v2/community/NewChatForm.translations";
import Notification from "@components/v2/community/Notification.translations";
import NotificationsList from "@components/v2/community/NotificationsList.translations";
import PostContent from "@components/v2/community/PostContent.translations";

const components = {
  AccountMenu,
  ActivateButton,
  ActivatePage,
  ActivityHelperText,
  CarePlan,
  CarePlanActionItemActionGroup,
  ChatThread,
  ChatWarning,
  ClientCommunityMemberPage,
  ClientCommunityNotificationPage,
  ClientCommunityPage,
  ClientCommunityPostPage,
  ClientInbox,
  ClientVisibleTaskResourceActions,
  ClientVisibleTaskResourceUsefulnessButtons,
  ClosedChats,
  Community,
  CommunityGroup,
  CommunityMembersSearch,
  CommunityNotificationPage,
  CommunityNotificationsForm,
  ConnectionsList,
  EmptySelfServeCarePlan,
  ExpertCalendarPage,
  ExpertConsultBuffersForm,
  ExpertWorkingHoursList,
  Goal,
  Goals,
  GroupForm,
  HeroCardCarePlanBody,
  HeroCardCarePlanItemsBody,
  HeroCardScheduledConsultBody,
  HeroCardWorkingOnIt,
  Home,
  JourneySelector,
  LeaveChatModal,
  NewChatForm,
  Notification,
  NotificationCenter,
  NotificationsList,
  OutOfOfficeCalendar,
  PostContent,
  Providers,
  SearchForm,
  SignConsentFormWizard,
  SuggestedForYou,
  UploadDocumentsWizard,
  UploadInsuranceWizard,
  WorkingHourBlockForm
};

export default components;
