const pageTitles = {
  admin: {
    admin: {
      edit: "Grayce Admin - Edit Admin",
      list: "Grayce Admin - Admins"
    },
    article: {
      edit: "Grayce Admin - Edit Article",
      list: "Grayce Admin - All Articles",
      new: "Grayce Admin - New Article",
      preview: "Grayce Admin - Preview Article",
      show: "Grayce Admin - Article"
    },
    automation: {
      applications: "Grayce Admin - Automation Applications",
      edit: "Grayce Admin - Edit Automation",
      list: "Grayce Admin - All Automations",
      new: "Grayce Admin - New Automation",
      show: "Grayce Admin - Automation"
    },
    automationApplication: {
      show: "Grayce Admin - Automation Application"
    },
    carePlanTaskTemplate: {
      edit: "Grayce Admin - Edit Task Template",
      list: "Grayce Admin - All Task Templates",
      new: "Grayce Admin - New Task Template",
      show: "Grayce Admin - Task Template"
    },
    client: {
      show: "Grayce Admin - Member"
    },
    community: {
      group: "Grayce Admin - Community Group",
      member: "Grayce Admin - Community Member",
      notification: "Grayce Admin - Community Notification",
      post: "Grayce Admin - Community Post",
      table: "Grayce Admin - Community Table"
    },
    consultConflicts: {
      list: "Grayce Admin - Session Conflicts"
    },
    dashboard: {
      show: "Grayce Admin - Dashboard"
    },
    demo: {
      show: "Grayce Admin - Demo"
    },
    eligibility: {
      list: "Grayce Admin - Eligibility List"
    },
    eligibilityFile: {
      list: "Grayce Admin - Eligibility Files"
    },
    emailMessage: {
      list: "Grayce Admin - All Email Messages",
      show: "Grayce Admin - Email Message"
    },
    employer: {
      edit: "Grayce Admin - Edit Employer",
      list: "Grayce Admin - All Employers",
      new: "Grayce Admin - New Employer",
      qbr: "Grayce Admin - Employer QBR",
      show: "Grayce Admin - Employer"
    },
    employerBenefits: {
      edit: "Grayce Admin - Edit Employer Benefit",
      list: "Grayce Admin - Employer Benefits",
      new: "Grayce Admin - New Employer Benefit",
      preview: "Grayce Admin - Preview Employer Benefit",
      show: "Grayce Admin - Employer Benefit"
    },
    employerContract: {
      show: "Grayce Admin - Employer Contract"
    },
    expert: {
      edit: "Grayce Admin - Edit Care Partner",
      list: "Grayce Admin - All Care Partners",
      new: "Grayce Admin - New Care Partner",
      show: "Grayce Admin - Care Partner"
    },
    goalTemplate: {
      edit: "Grayce Admin - Edit Goal",
      list: "Grayce Admin - All Goals",
      new: "Grayce Admin - New Goal",
      show: "Grayce Admin - Goal"
    },
    internalNoteTemplate: {
      edit: "Grayce Admin - Edit Internal Note Template",
      list: "Grayce Admin - All Internal Note Templates",
      new: "Grayce Admin - New Internal Note Template",
      show: "Grayce Admin - Internal Note Template"
    },
    journey: {
      expertRole: "Grayce Admin - Journey Care Partner Role",
      list: "Grayce Admin - All Journeys",
      timeLogs: "Grayce Admin - Journey Time Logs"
    },
    message: {
      list: "Grayce Admin - All Messages",
      show: "Grayce Admin - Message"
    },
    messageTemplate: {
      list: "Grayce Admin - All Message Templates"
    },
    messageTemplateCategory: {
      list: "Grayce Admin - All Message Template Categories"
    },
    oidcProvider: {
      edit: "Grayce Admin - Edit SSO provider",
      list: "Grayce Admin - All SSO providers",
      new: "Grayce Admin - New SSO provider"
    },
    outOfOffice: {
      list: "Grayce Admin - All Out of Office Periods"
    },
    packageGrant: {
      list: "Grayce Admin - Package Grants",
      show: "Grayce Admin - Package Grant"
    },
    peerSupportRequests: {
      list: "Grayce Admin - Peer Support Requests"
    },
    refreshPeriod: {
      show: "Grayce Admin - Refresh Period"
    },
    tag: {
      list: "Grayce Admin - Tags"
    },
    tagging: {
      list: "Grayce Admin - Taggings"
    },
    timesheets: {
      list: "Grayce Admin - All Timesheets"
    },
    webinar: {
      edit: "Grayce Admin - Edit Live Event",
      list: "Grayce Admin - All Live Events",
      new: "Grayce Admin - New Live Event",
      show: "Grayce Admin - Live Event"
    },
    webinarRegistrations: {
      list: "Grayce Admin - Live Event registrations"
    },
    worksheetTemplate: {
      edit: "Grayce Admin - Edit Worksheet",
      list: "Grayce Admin - Worksheets",
      new: "Grayce Admin - New Worksheet",
      show: "Grayce Admin - Worksheet"
    }
  },
  auth: {
    email: {
      confirm: "Grayce - Confirm Your Email"
    },
    inviteeRegistration: {
      create: "Grayce - Accept Invitation"
    },
    login: {
      create: "Grayce - Log In"
    },
    password: {
      new: "Grayce - New Password",
      reset: "Grayce - Reset Password",
      resetSuccess: "Grayce - Password Reset"
    },
    registration: {
      create: "Grayce - Sign Up",
      new: "Grayce - Sign Up"
    },
    twoFactorAuthentication: {
      verify: "Grayce - Two Factor Authentication"
    },
    waitingForApproval: {
      show: "Grayce - Waiting For Approval"
    },
    workEmail: {
      resendConfirmWorkEmail: "Grayce - Work Email Confirmation"
    }
  },
  client: {
    article: {
      show: "Grayce - Article"
    },
    careJourney: {
      show: "Grayce - Care Journey"
    },
    carePlan: {
      show: "Grayce - Care Plan"
    },
    carePlanFocusArea: {
      show: "Grayce - Care Plan Focus Area"
    },
    careProfile: {
      show: "Grayce - Profile"
    },
    clientVisibleTask: {
      show: "Grayce - Service"
    },
    community: {
      show: "Grayce - Community",
      waitlist: "Grayce - Gather waitlist"
    },
    consult: {
      cancel: "Grayce - Cancel Session",
      join: "Grayce - Join Session",
      list: "Grayce - Session"
    },
    consultReport: {
      show: "Grayce - Session Report"
    },
    drive: {
      list: "Grayce - Drive"
    },
    events: {
      show: "Grayce - Events"
    },
    expert: {
      show: "Grayce - Your Care Partner"
    },
    explore: {
      show: "Grayce - Explore"
    },
    folder: {
      show: "Grayce - Folder"
    },
    goalTemplate: {
      list: "Grayce - All Goals",
      new: "Grayce - New Goal",
      show: "Grayce - Goal"
    },
    home: {
      show: "Grayce - Home"
    },
    learn: {
      show: "Grayce - Library"
    },
    marketplace: {
      list: "Grayce - Marketplace",
      show: "Grayce - Marketplace"
    },
    messages: {
      show: "Grayce - Messages"
    },
    schedule: {
      show: "Grayce - Schedule Session"
    },
    worksheet: {
      list: "Grayce - Worksheets",
      show: "Grayce - Worksheet"
    }
  },
  dmca: "Grayce Community DMCA",
  expert: {
    account: {
      show: "Grayce Care Partner - Account"
    },
    activity: {
      list: "Grayce Care Partner - Activities"
    },
    ai: {
      list: "Grayce Care Partner - AI"
    },
    allJourney: {
      list: "Grayce Care Partner - All Journeys"
    },
    automation: {
      edit: "Grayce Care Partner - Edit Automation",
      list: "Grayce Care Partner - All Automations",
      new: "Grayce Care Partner - New Automation",
      show: "Grayce Care Partner - Automation"
    },
    automationApplication: {
      list: "Grayce Care Partner - Automation Applications",
      show: "Grayce Care Partner - Automation Application"
    },
    calendar: {
      show: "Grayce Care Partner - Calendar"
    },
    carePlan: {
      list: "Grayce Care Partner - Care Plans"
    },
    carePlanTaskTemplate: {
      edit: "Grayce Care Partner - Edit Task Template",
      list: "Grayce Care Partner - All Task Templates",
      new: "Grayce Care Partner - New Task Template",
      show: "Grayce Care Partner - Task Template"
    },
    community: {
      show: "Grayce Care Partner - Community"
    },
    consult: {
      list: "Grayce Care Partner - Sessions"
    },
    consultConflict: {
      list: "Grayce Care Partner - Session Conflicts"
    },
    dashboard: {
      show: "Grayce Care Partner - Dashboard"
    },
    dashboardConsults: {
      list: "Grayce Admin - Dashboard Sessions"
    },
    dashboardCptat: {
      list: "Grayce Care Partner - Dashboard Care Plan Turn Around Times"
    },
    dashboardSignUps: {
      list: "Grayce Care Partner - Dashboard Sign Ups"
    },
    dashboardTimeLogs: {
      list: "Grayce Care Partner - Dashboard Time Logs"
    },
    employer: {
      list: "Grayce Care Partner - Employers"
    },
    expert: {
      edit: "Grayce Care Partner - Edit Care Partner",
      journeyList: "Grayce Care Partner - Care Partner Journeys",
      list: "Grayce Care Partner - All Care Partners"
    },
    goalTemplate: {
      edit: "Grayce Care Partner - Edit Goal",
      list: "Grayce Care Partner - Goals",
      new: "Grayce Care Partner - New Goal",
      show: "Grayce Care Partner - Goal"
    },
    internalNoteTemplate: {
      edit: "Grayce Care Partner - Edit Internal Note Template",
      list: "Grayce Care Partner - All Internal Note Templates",
      new: "Grayce Care Partner - New Internal Note Template",
      show: "Grayce Care Partner - Internal Note Template"
    },
    journey: {
      list: "Grayce Care Partner - Journeys"
    },
    message: {
      list: "Grayce Care Partner - All Messages",
      show: "Grayce Care Partner - Message"
    },
    messageTemplate: {
      list: "Grayce Care Partner - All Message Templates",
      show: "Grayce Care Partner - Message Template"
    },
    messageTemplateCategory: {
      list: "Grayce Care Partner - All Message Templates Categories"
    },
    outOfOfficePeriod: {
      list: "Grayce Care Partner - Out of Office"
    },
    packageGrant: {
      show: "Grayce Care Partner - Package Grant"
    },
    resourceFeatureTemplate: {
      edit: "Grayce Care Partner - Edit resource feature template",
      list: "Grayce Care Partner - Resource feature templates",
      new: "Grayce Care Partner - New resource feature template"
    },
    tag: {
      list: "Grayce Care Partner - Tags",
      manageList: "Grayce Care Partner - All Tags"
    },
    task: {
      list: "Grayce Care Partner - Tasks"
    },
    timeLog: {
      list: "Grayce Care Partner - Time tracking"
    }
  },
  expertJourney: {
    activity: {
      list: "Grayce Care Partner Journey - Activities"
    },
    article: {
      list: "Grayce Care Partner Journey - Articles",
      show: "Grayce Care Partner Journey - Article"
    },
    careJourney: {
      show: "Grayce Care Partner Journey - Care Journey"
    },
    carePlan: {
      edit: "Grayce Care Partner Journey - Edit Care Plan",
      show: "Grayce Care Partner Journey - Care Plan"
    },
    carePlanDraft: {
      show: "Grayce Care Partner Journey - Care Plan Draft"
    },
    carePlanFocusArea: {
      show: "Grayce - Care Plan Focus Area"
    },
    careProfile: {
      show: "Grayce Care Partner Journey - Profile"
    },
    clientVisibleTask: {
      show: "Grayce Care Partner Journey - Service"
    },
    community: {
      show: "Grayce Care Partner Journey - Community"
    },
    consult: {
      cancel: "Grayce Care Partner Journey - Cancel Session",
      join: "Grayce Care Partner Journey - Join Session",
      list: "Grayce Care Partner Journey - Sessions"
    },
    consultReport: {
      edit: "Grayce Care Partner Journey - Edit Session Report",
      show: "Grayce Care Partner Journey - Session Report"
    },
    drive: {
      list: "Grayce Care Partner Journey - Drive"
    },
    employer: {
      show: "Grayce Care Partner Journey - Employer"
    },
    expert: {
      show: "Grayce Care Partner Journey - Care Partner"
    },
    explore: {
      show: "Grayce Care Partner Journey - Explore"
    },
    folder: {
      show: "Grayce Care Partner Journey - Folder"
    },
    goalTemplate: {
      edit: "Grayce Care Partner Journey - Edit Goal",
      list: "Grayce Care Partner Journey - Goals",
      new: "Grayce Care Partner Journey - New Goal",
      show: "Grayce Care Partner Journey - Goal"
    },
    home: {
      show: "Grayce Care Partner Journey - Home"
    },
    initialConsultReport: {
      edit: "Grayce Care Partner Journey - Edit Initial Session Report",
      show: "Grayce Care Partner Journey - Initial Session Report"
    },
    internalNote: {
      list: "Grayce Care Partner Journey - Internal Notes"
    },
    internalNoteHistory: {
      list: "Grayce Care Partner Journey - Internal Notes History"
    },
    learn: {
      show: "Grayce Care Partner Journey - Library"
    },
    marketplace: {
      list: "Grayce - Marketplace",
      show: "Grayce - Marketplace"
    },
    messages: {
      show: "Grayce Care Partner Journey - Message"
    },
    timeLog: {
      list: "Grayce Care Partner Journey - Time Logs"
    },
    worksheet: {
      list: "Grayce Care Partner Journey - Worksheets",
      show: "Grayce Care Partner Journey - Worksheet"
    }
  },
  faq: "Grayce - FAQ",
  onboarding: "Grayce - Onboarding",
  referACoworkerAgreementPage: "Refer A Coworker Program Agreement",
  unauthenticated: {
    activate: {
      show: "Grayce"
    },
    article: {
      show: "Grayce - Article"
    },
    webinar: {
      list: "Grayce - Live Events",
      show: "Grayce - Live Event"
    }
  }
};

export default pageTitles;
