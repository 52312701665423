const translations = {
  complete: {
    email: {
      description: "The consent form has been emailed to {recipientName}.",
      title: "Thank you!"
    },
    embed: {
      description: "Thanks for signing the consent form. Close this window to return to your Care Plan.",
      title: "Thank you!"
    },
    upload: {
      description: "Thanks for uploading the consent form. Close this window to return to your Care Plan.",
      title: "Thank you!"
    }
  },
  existing: {
    EMAIL: {
      button: "Restart",
      description:
        "You started the consent form. It has been emailed to {recipientName} but they haven't signed it yet.",
      title: "In progress"
    },
    EMBED: {
      button: "Sign it now",
      description: "You started the consent form but haven't signed it yet.",
      secondaryButton: "Start over",
      title: "In progress"
    },
    UPLOAD: {
      button: "Upload it now",
      description: "You started the consent form but haven't uploaded it yet.",
      secondaryButton: "Start over",
      title: "In progress"
    }
  },
  start: {
    description:
      "In order for your Care Partner to provide the highest level of concierge support and guidance, Grayce requests signed authorization to exchange information with third-party providers.",
    title: "Sign consent form"
  }
};

export default translations;
