const translations = {
  delete: {
    button: "Delete group",
    confirm: "Are you sure you want to delete this group?",
    failure: "Failed to delete this group.",
    success: "You deleted this group."
  },
  discard: {
    button: "Discard changes",
    confirm: "Are you sure you want to discard your changes?"
  },
  errors: {
    description: {
      required: "A description is required"
    },
    imageBlobUploads: {
      max: "Only one image is allowed",
      min: "A group image is required"
    },
    membershipType: {
      required: "A membership type is required"
    },
    name: {
      required: "The group's name is required"
    },
    postingPermission: {
      required: "A posting permission is required"
    }
  },
  fields: {
    description: {
      label: "Description",
      placeholder: "Description"
    },
    memberIds: {
      placeholder: "Add members"
    },
    membershipType: {
      label: "Membership type",
      options: {
        mandatory: {
          description: "Every member will be automatically added and cannot leave.",
          label: "Mandatory"
        },
        private: {
          description: "Members have to request to join to see all content.",
          label: "Private"
        },
        public: {
          description: "Everyone can view this group's content and can join.",
          label: "Public"
        },
        secret: {
          description: "Only members added by the community host know this group exists.",
          label: "Secret"
        }
      }
    },
    name: {
      label: "Group name",
      placeholder: "Group name"
    },
    postingPermission: {
      label: "Posting permission",
      options: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        admins_only: "Admins only",
        everyone: "Everyone"
      }
    }
  }
};

export default translations;
