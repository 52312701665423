const translations = {
  /* eslint-disable @typescript-eslint/naming-convention */
  upload_documents: {
    subtext: "{incompleteCount} of {totalCount} document requests remaining"
  },
  upload_insurance: {
    subtext: "{incompleteCount} of {totalCount} insurance requests remaining"
  }
  /* eslint-enable @typescript-eslint/naming-convention */
};

export default translations;
