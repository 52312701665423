const translations = {
  groups: {
    seeAllGroups: "See all groups",
    title: "Groups"
  },
  post: {
    cta: "Open in Gather"
  },
  toggle: {
    feed: "Feed",
    groups: "Groups",
    myPosts: "My posts",
    notifications: "Notifications"
  }
};

export default translations;
