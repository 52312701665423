const translations = {
  comment: {
    deleted: "Comment deleted"
  },
  post: {
    deleted: "Post deleted"
  }
};

export default translations;
