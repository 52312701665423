const translations = {
  COMMENT: {
    withParent: "replied to your comment",
    withoutParent: "commented on your post"
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  MODERATOR_DELETED_POST: {
    withParent: "Your comment was removed",
    withoutParent: "Your post was removed"
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  POST_REPORTED: {
    withParent: "reported a comment for your review",
    withoutParent: "reported a post for your review"
  },
  REACTION: {
    withParent: "reacted to your comment",
    withoutParent: "reacted to your post"
  },
  sender: "and {count, plural, one {# other} other {# others}}"
};

export default translations;
