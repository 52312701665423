const translations = {
  COMMENT: {
    body: "Someone commented on your post!",
    button: "View post"
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  MODERATOR_DELETED_POST: {
    body: "Your content was removed for violating our community guidelines. Please review our guidelines to ensure a supportive space for all.",
    button: "View guidelines"
  },
  REACTION: {
    body: "Someone reacted to your post!",
    button: "View post"
  },
  header: "Notification",
  notFound: "Notification not found"
};

export default translations;
