const translations = {
  complete: {
    title: "Upload complete!"
  },
  start: {
    description:
      "Your Care Partner has requested a document to help support your care journey. Please upload a clear copy in one of these formats: PDF, JPG, or PNG.\n\nIf you have any questions about this request, contact your Care Partner.",
    title: "Upload a document"
  },
  upload: {
    inputLabel: "File name",
    title: "Upload a document"
  }
};

export default translations;
