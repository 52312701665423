const translations = {
  directMessages: "New messages",
  groupPosts: "Posts in your groups",
  heading: "Notifications for community activity",
  postComments: "Comments on your posts",
  pushDisabledTooltip:
    "Use the mobile app to allow Grayce to send you push notifications before changing these settings.",
  pushPermissionDeniedAlert: {
    header: "Push notifications are disabled",
    message:
      "You have disabled push notifications for this app. Please enable them in your device settings to receive notifications."
  },
  weeklyEmails: "Weekly digests"
};

export default translations;
